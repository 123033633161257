import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: '"Merriweather", serif',
        heading: '"Fira Sans", sans-serif',
        monospace: '"Merriweather", serif',
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#2D445B',
        background: '#fff',
        grey: '#506F9A',
        primary: '#2873D9',
        secondary: '#00C6FF',
        muted: '#F7FBFF',
    },
}